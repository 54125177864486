<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="permissao('responsavel_editar')" exact :to="`/responsavel/alterar/${id_responsavel}`" icon class="py-4"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="permissao('responsavel_excluir')" exact @click="excluir" icon class="py-4"><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn to="/responsavel" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Responsável:</strong> {{responsavel.razao_social_nome_completo}}</p>
                            <p class="text--primary mb-2"><strong>ID:</strong> {{responsavel.id_responsavel}}</p>
                            <p class="text--primary mb-2"><strong>CPF:</strong> {{responsavel.cnpj_cpf}}</p>
                            <p class="text--primary mb-2" v-if="responsavel.estado_civil"><strong>Estado Civil:</strong> {{responsavel.estado_civil}}</p>
                            <p class="text--primary mb-2" v-if="responsavel.sexo"><strong>Sexo:</strong> {{responsavel.sexo == 't' ? "Masculino" : "Feminino"}}</p>
                            <p class="text--primary mb-2" v-if="responsavel.data_nascimento"><strong>Nascimento:</strong> {{dataPt(responsavel.data_nascimento)}}</p>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Celular:</strong> {{responsavel.celular}}</p>
                            <p class="text--primary mb-2" v-if="responsavel.email"><strong>E-mail:</strong> {{responsavel.email}}</p>
                            <p class="text--primary mb-2"><strong>Endereço:</strong> {{responsavel.logradouro}} {{responsavel.numero ? 'Nº' : ''}} {{responsavel.numero}}, {{responsavel.bairro}} - {{responsavel.cidade}} {{responsavel.uf}}</p>
                            <p class="text--primary mb-2"><strong>CEP:</strong> {{responsavel.cep}}</p>
                            <p class="text--primary mb-2" v-if="responsavel.observacao"><strong>Observação:</strong> {{responsavel.observacao}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-tabs v-model="tab" class="elevation-3" show-arrows dark background-color="primary">
                <v-tab>Alunos</v-tab>
                <!--<v-tab>* Contratos</v-tab>
                <v-tab>* Financeiro</v-tab>-->
                <v-tab>Franquias</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="elevation-3">
                <v-tab-item>
                    <v-divider/>
                    <AlunoListar :filtro="{id_responsavel : parseInt(id_responsavel)}"/>
                </v-tab-item>
                <!--<v-tab-item>
                    <v-divider/>
                    <v-card-text>Listar contratos aqui</v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-card-text>Listar contas a receber e movimentações</v-card-text>
                </v-tab-item>-->
                <v-tab-item>
                    <v-divider/>
                    <FranquiaListar :filtro="{id_responsavel : parseInt(id_responsavel)}"/>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>

import AlunoListar from "@/Views/Aluno/Component/AlunoListar"
import FranquiaListar from "@/Views/Franquia/Component/FranquiaListar"
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "ResponsavelGerenciar",
    components: {FranquiaListar, AlunoListar},
    props : ['id_responsavel'],
    data() {
        return {
            tab : null,
            responsavel : {}
        }
    },
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        get() {
            return axios.post(`${this.baseUrl}responsavel/get`, {id_responsavel : this.id_responsavel}).then( (res) => {

                this.responsavel = res.data
            })
        },
        excluir() {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")

            if(c) {
                return axios.post(`${this.baseUrl}responsavel/excluir`, {id_responsavel : this.id_responsavel}).then( (res) => {

                    if (res.data) {

                        this.$router.push({path:'/responsavel'})
                    } else {

                        alert("Erro ao excluir registro")
                    }
                })
            }
        }
    },
    activated() {
        this.get()
    }
}
</script>

<style scoped>

</style>
